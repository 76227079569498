import React,{useEffect,useState} from 'react';

const SchemaMarkup = ({ data }) => { 
  const [status, setStatus] = useState(false);
  useEffect(() => {
    setStatus(true);
  }, []);
  return(status &&  <script defer type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}/> 
)};

export default SchemaMarkup;